import '../../utils/jquery-3.3.1.min.js';
import '../../utils/jquery.cookie.js';
import '../../utils/oha_api.js';
import '../../utils/oha_config.js';
import '../../utils/oha_timer.js';
import '../../android/files/script';
import '../../files/script_top';
import oha_api from "../../utils/oha_api";
var myVar;
import { useRouter } from "vue-router";
import { usetime, patternlist } from "../../api/application/index";
import { antiShake } from '../../utils/fd';
var oha_timer;
var omass_config = {
  mode: 3,
  pat: 1,
  v: 5,
  w: 32,
  // unit =25us 
  f: 10 // unit = ms
};

import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      bigshow: false,
      typename: '',
      activeindex: 0,
      level: 10,
      frequency: 30,
      wave: 25,
      type: 1,
      countDown: null,
      minutes: 10,
      seconds: 0,
      timer: null,
      startTime: null,
      endTime: null,
      tipshow: false,
      waveindex: 0,
      //传进cmd脉波的value
      list: [],
      demoshow: false,
      info: {}
    };
  },
  beforeCreate() {
    console.log('准备初始化');
  },
  mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      this.$router.go(-1);
      location.reload();
      return;
    }
    this.demoshow = true;
    console.log('准备');
    localStorage.setItem('waveindex', 0);
    localStorage.setItem('frequencyindex', 30);
    window['OHA_DevReady'] = () => {
      this.OHA_DevReady();
    };
    window['OHA_DevOff'] = () => {
      this.OHA_DevOff();
    };
    window['OHA_INTR'] = () => {
      this.OHA_INTR();
    };
    window['OHA_RET'] = () => {
      this.OHA_RET();
    };
    window['OHA_UiNew'] = () => {
      this.OHA_UiNew();
    };
    window['OHA_iOSReady'] = () => {
      this.OHA_iOSReady();
    };
    this.getpatternlist();
  },
  watch: {
    second: {
      handler(newVal) {
        this.num(newVal);
      }
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      }
    }
  },
  computed: {
    second: function () {
      return this.num(this.seconds);
    },
    minute: function () {
      return this.num(this.minutes);
    }
  },
  methods: {
    getpatternlist() {
      patternlist({
        parentId: this.$route.query.parentId
      }).then(res => {
        console.log(res);
        this.list = res.data;
        this.info = this.list[0];
        localStorage.setItem('activeindex', this.list[0].patternId - 1);
        this.typename = this.list[0].name;
      });
    },
    num(n) {
      return n < 10 ? '0' + n : '' + n;
    },
    //选择功能
    changemode(index, patternId) {
      // if(this.level < 31){
      //   this.level = 30
      // }
      this.activeindex = index;
      this.info = this.list[index];
      this.typename = this.list[index].name;
      localStorage.setItem('activeindex', patternId - 1);
      console.log(this.activeindex);
    },
    //控制强度
    add: antiShake(function () {
      console.log(111);
      if (this.level < 400) {
        this.level = this.level + 5;
        setTimeout(() => {
          $('#incBtnH')[0].click();
        }, 100);
      }
      console.log('强度' + this.level);
    }),
    //控制强度
    del: antiShake(function () {
      console.log(this.level);
      if (this.level > 5) {
        this.level = this.level - 5;
        setTimeout(() => {
          $('#decBtnH')[0].click();
        }, 100);
      }
      console.log('强度' + this.level);
    }),
    //控制频率
    addwave: antiShake(function () {
      if (this.wave < 50) {
        this.waveindex++;
        this.wave = this.wave + 25;
      } else if (this.wave < 200) {
        this.wave = this.wave + 50;
        this.waveindex++;
      } else if (this.wave < 600) {
        this.wave = this.wave + 100;
        this.waveindex++;
      } else if (this.wave == 600) {
        this.wave = this.wave + 200;
        this.waveindex++;
      }
      localStorage.setItem('waveindex', this.waveindex);
    }),
    //减弱脉波
    delwave: antiShake(function () {
      if (this.wave < 26) {
        return;
      }
      if (this.wave < 100) {
        this.waveindex--;
        this.wave = this.wave - 25;
      } else if (this.wave < 300) {
        this.wave = this.wave - 50;
        this.waveindex--;
      } else if (this.wave < 800) {
        this.wave = this.wave - 100;
        this.waveindex--;
      } else if (this.wave < 801) {
        this.wave = this.wave - 200;
        this.waveindex--;
      }
      localStorage.setItem('waveindex', this.waveindex);
    }),
    //控制频率
    addfrequency: antiShake(function () {
      let frequencyindex = 0;
      // if(this.frequency <= 34){
      if (this.frequency < 100) {
        this.frequency = this.frequency + 10;
        localStorage.setItem('frequencyindex', this.frequency);
      }

      // }
      // this.frequency = this.frequency + 1;
      console.log('频率' + this.frequency);
    }),
    //控制频率
    delfrequency() {
      if (this.frequency > 10) {
        this.frequency = this.frequency - 10;
        console.log(this.frequency);
      }
    },
    //增加时间
    addtime() {
      this.minutes = this.minutes + 1;
      //   $(".warp-bottom").hide();
      console.log("这是增加时间");
    },
    //删减时间
    deltime() {
      if (this.minutes > 0) {
        this.minutes = this.minutes - 1;
        $(".warp-bottom").show();
      }
    },
    //启动
    open() {
      let time = new Date();
      this.startTime = this.timestampToTime(time.toLocaleString('en-US', {
        hour12: false
      }).split(" "));
      localStorage.setItem('startTime', this.startTime);
      console.log('启动');
      this.type = 2;
      this.cutdowntime();
    },
    //启动
    close() {
      this.type = 1;
      this.minutes = 10;
      this.seconds = 0;
      console.log('暂停');
      clearInterval(myVar);
    },
    ready() {
      console.log('重新开始');
      this.type = 1;
      this.minutes = 10;
      this.seconds = 0;
    },
    cutdowntime() {
      var _this = this;
      myVar = setInterval(function () {
        if (_this.seconds === 0 && _this.minutes !== 0) {
          _this.seconds = 59;
          _this.minutes -= 1;
        } else if (_this.minutes === 0 && _this.seconds === 0) {
          _this.seconds = 0;
          clearInterval(myVar);
          setTimeout(() => {
            $('#powerOnNormal')[0].click();
          }, 100);
          console.log('倒计时结束');
        } else {
          _this.seconds -= 1;
        }
      }, 1000);
    },
    timestampToTime(times) {
      let time = times[1];
      let mdy = times[0];
      mdy = mdy.split('/');
      let month = parseInt(mdy[0]);
      let day = parseInt(mdy[1]);
      let year = parseInt(mdy[2]);
      return year + '-' + month + '-' + day + ' ' + time;
    },
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      let time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      this.startTime = time;
      console.log('当前时间=' + time);
    },
    onClickLeft() {
      $('#powerOnNormal')[0].click();
      this.$router.replace({
        path: '/api/index/default.html'
      });
    },
    OHA_DevReady(_id, _model, _url, _uuid, _verFw, _intfType, _stus) {
      console.log('88888888888888');
      //    MyLog("OHA_DevReady, _model="+_model+", ohaConfig.model="+oha_api.ohaConfig.model);
      if (_model == "" || _model == "null") {
        return;
      }
      if (typeof _stus != undefined) {
        oha_api.__connectStatus = _stus;
      }
      //	for(var i=0;i<arguments.length;i++) {
      //		console.log("OHA_DevReady, arguments["+i+"="+arguments[i])
      //	}
      //	console.log("OHA_DevReady, arguments.length="+arguments.length)
      //	console.log("OHA_DevReady, oha_api._curDevArguments.length="+oha_api._curDevArguments.length)
      if (arguments.length == oha_api._curDevArguments.length) {
        var isSame = true;
        for (var i = 0; i < arguments.length; i++) {
          //				console.log("_curDevArguments["+i+"]="+oha_api._curDevArguments[i]);
          //				console.log("arguments["+i+"]="+arguments[i]);
          if (oha_api._curDevArguments[i] != arguments[i]) {
            //				console.log("isSame=false!!!!!")
            isSame = false;
            //				alert("isSame=false, idx="+i+", cur="+oha_api._curDevArguments[i]+", new="+arguments[i]);
            break;
          }
        }
        if (isSame) {
          //			console.log("OHA_DevReady return ")
          return;
        }
      }
      oha_api._curDevArguments = arguments;
      //	console.log("OHA_DevReady cpx")
      var _modelX = _model.split(":");
      oha_api.ohaConfig.isReadOnly = false;
      for (var i = 0; i < _modelX.length; i++) {
        if (_modelX[i].toLocaleUpperCase() == "RO") {
          oha_api.ohaConfig.isReadOnly = true;
        }
      }
      if (!oha_api.ohaConfig.isCheckModelName || _modelX[0] == oha_api.ohaConfig.model) {
        //        MyLog("Hello, model matches")
        if (typeof arguments[3] == "string") {
          //		console.log("arguments[3]="+arguments[3]);
          oha_api.__uuid = arguments[3];
        }
        if (typeof arguments[4] !== "undefined") {
          //		console.log("arguments[3]="+arguments[3]);
          oha_api.__verFw = parseFloat(arguments[4]);
          if (isNaN(oha_api.__verFw)) {
            oha_api.__verFw = arguments[4];
          }
        }
        if (oha_api.getPlateForm() == oha_api.PlateFormOpt.Apple) {
          if (oha_api.DeviceId != _id) {
            oha_api.DeviceId = _id;
            oha_api.__devPlugInP(_id, _model, arguments[2]);
            oha_api.DevPlugIn(_model, arguments[2]);
          }
        } else {
          oha_api.DeviceId = _id;
          oha_api.__devPlugInP(_id, _model, arguments[2]);
          oha_api.DevPlugIn(_model, arguments[2]);
        }
        if (arguments.length > 2) {
          console.log("oha_api.OHA_DevReady before readRemoteJson(" + arguments[2] + ")");
          oha_api.readRemoteJson(arguments[2]);
        }
      }
      //	console.log("OHA_DevReady, arguments[3]="+arguments[3]);
    },

    OHA_DevOff(_id) {
      console.log('这个是OHA_UiNew，线被拔出了');
      $('#powerOnNormal')[0].click();
      this.type = 1;
      this.minutes = 10;
      this.seconds = 0;
      console.log('关闭');
      clearInterval(myVar);
      oha_api.__uuid = null;
      oha_api.__verFw = null;
      oha_api._curDevArguments = arguments;
      oha_api.__connectStatus = null;
      oha_api.__devPlugOutP(_id);
      //    oha_api.DevPlugOut();
      //    oha_api.DeviceId=-1;
    },

    OHA_INTR(_id, _data) {
      if (_id == oha_api.DeviceId) {
        oha_api.OHA_INTR(_data);
      }
    },
    OHA_RET(_id, _srcCmd, _recvData) {
      var _srcCmd1 = _srcCmd;
      if (_id == oha_api.DeviceId) {
        if (_srcCmd.indexOf("G:") == 0) {
          _srcCmd1 = _srcCmd.substring(2);
        }
        oha_api.OHA_RET(_srcCmd1, _recvData);
      }
    },
    OHA_UiNew(_ver) {
      console.log('这个是OHA_UiNew');
      oha_api.UiNew(_ver);
    },
    OHA_iOSReady() {
      console.log('这个是OHA_iOSReady');
      oha_api.getIosStus()._isJsReady = true;
      oha_api.getIosStus().ready();
    }
  }
};